import { Combobox, Tab, TabGroup, TabList, Transition } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { ClockIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useMemo, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import {
	Area,
	AreaChart,
	CartesianGrid,
	Cell,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts'

import { CalendarImage } from 'assets/icons'
import { ReactComponent as CancelledIcon } from 'assets/svgs/cancelled.svg'
import { ReactComponent as CompletedIcon } from 'assets/svgs/completed.svg'
import { ReactComponent as PendingIcon } from 'assets/svgs/pending.svg'
import { ReactComponent as TaskListIcon } from 'assets/svgs/task-list.svg'
import { ReactComponent as TotalIcon } from 'assets/svgs/total.svg'
import { AppLayout } from 'components/app/layout'
import { MenuDropdown } from 'components/app/menu'
import { StatsCard } from 'components/cards/stats-cards'
import { AppointmentStatus, mappedAppointmentStatuses } from 'constants/constants'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import appointmentService from 'services/appointment-service'
import eventService from 'services/event-service'
import taskService from 'services/task-service'
import { getYears } from 'utils/date'
import { getTKey } from 'utils/language'
import './calendar.css'

export const Dashboard = () => {
	const { i18n, t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()

	const tKey = getTKey('dashboard')
	const yearList = getYears()

	const auth = useAppSelector(state => state.auth)

	const [appointmentStats, setAppointmentStats] = useState<
		{ title: string; value: number; icon: React.ElementType; link: string }[]
	>([])
	const [eventStats, setEventStats] = useState<
		{ title: string; value: number; icon: React.ElementType; link: string }[]
	>([])
	const [companyAppointments, setCompanyAppointments] = useState<Appointment[]>([])
	const [events, setEvents] = useState<CustomEvent[]>([])
	const [tasks, setTasks] = useState<Task[]>([])
	const [selectedTaskType, setSelectedTaskType] = useState(t(tKey('labels.backlog')))
	const [calendarValue, setCalendarValue] = useState<CalendarValue>(new Date())
	const [selectedStat, setSelectedStat] = useState('Appointment')
	const [selectedRange, setSelectedRange] = useState('monthly')
	const [selectedDuration, setSelectedDuration] = useState(DateTime.now().toFormat('yyyy'))
	const [weeklyRevenue, setWeeklyRevenue] = useState<WeeklyRevenue[]>([])
	const [monthlyRevenue, setMonthlyRevenue] = useState<MonthlyRevenue[]>([])
	const [query, setQuery] = useState('')

	useEffect(() => {
		eventService.getAllEvents().then(res => setEvents(res))
		taskService.getAllTasks().then(res => setTasks(res))
		eventService.getEventStatsByTime().then(res =>
			setEventStats([
				{
					title: t(tKey('stats.labels.totalEvents')),
					value: ((res?.upcomingCount as number) + res?.pastCount) as number,
					icon: TotalIcon,
					link: '/events'
				},
				{
					title: t(tKey('stats.labels.upcomingEvents')),
					value: res?.upcomingCount as number,
					icon: PendingIcon,
					link: '/events'
				},
				{
					title: t(tKey('stats.labels.pastEvents')),
					value: res?.pastCount as number,
					icon: CompletedIcon,
					link: `/events`
				}
			])
		)
		appointmentService.getAppointmentStats().then(res =>
			setAppointmentStats([
				{
					title: t(tKey('stats.labels.totalAppointments')),
					icon: TotalIcon,
					value: res?.totalAppointment as number,
					link: '/appointments'
				},
				{
					title: t(tKey('stats.labels.pendingAppointments')),
					icon: PendingIcon,
					value: res?.pendent as number,
					link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.PENDENT]}`
				},
				{
					title: t(tKey('stats.labels.cancelledAppointments')),
					icon: CancelledIcon,
					value: res?.canceled as number,
					link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.CANCELLED]}`
				},
				{
					title: t(tKey('stats.labels.completeAppointments')),
					icon: CompletedIcon,
					value: res?.abschluss as number,
					link: `/appointments?status=${mappedAppointmentStatuses[AppointmentStatus.COMPLETED]}`
				}
			])
		)
		appointmentService
			.getCompanyAppointments(auth.companyId)
			.then(res => setCompanyAppointments(res))
	}, [])

	useEffect(() => {
		if (selectedRange === 'monthly') {
			appointmentService.getRevenueStatsByWeek().then(data => setWeeklyRevenue(data))
		} else {
			if (selectedDuration) {
				appointmentService
					.getRevenueStatsByYear(Number(selectedDuration))
					.then(data => setMonthlyRevenue(data))
			}
		}
	}, [selectedDuration, selectedRange])

	const appointmentsdata = [
		{
			name: appointmentStats[0]?.title,
			value: (appointmentStats[0]?.value || 1) - appointmentStats[3]?.value
		},
		{ name: appointmentStats[3]?.title, value: appointmentStats[3]?.value }
	]
	const APPOINTMENT_COLORS = ['#D9D7E4', '#5964D3']

	const eventsdata = [
		{
			name: t(tKey('labels.totalEvents')),
			value: (eventStats[0]?.value || 1) - eventStats[2]?.value
		},
		{ name: t(tKey('labels.completed')), value: eventStats[2]?.value }
	]
	const EVENT_COLORS = ['#D9D7E4', '#13BAB4']

	const backlogTasks = tasks.filter(task => task.status === 'not started')
	const inProgressTasks = tasks.filter(task => task.status === 'in progress')
	const completedTasks = tasks.filter(task => task.status === 'completed')

	const tasksByType = {
		[t(tKey('labels.backlog'))]: backlogTasks,
		[t(tKey('labels.inProgress'))]: inProgressTasks,
		[t(tKey('labels.completed'))]: completedTasks
	} as { [type: string]: Task[] }

	const filteredOptions = useMemo(() => {
		if (yearList) {
			return yearList.filter(year => {
				return (
					query === '' || year.replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return yearList
		}
	}, [yearList, query])

	return (
		<AppLayout title={t(tKey('titles.dashboard'))} isHeaderVisible={!isMobile}>
			<div className="bg-background max-md:overflow-x-hidden min-h-screen">
				<div className="flex max-md:overflow-x-hidden max-md:flex-col max-md:gap-4 items-start md:justify-between">
					<div className="md:basis-[75%] max-md:w-full basis-full flex flex-col gap-4 md:gap-6 max-md:p-4 max-md:pb-0 md:p-6">
						<div
							style={{ boxShadow: '0px 4px 24px 0px #CFD8DC4D' }}
							className="bg-[#B790D899] p-4 pr-6 md:pr-14 rounded-[20px] border border-[#CFD8DC80] flex items-center justify-between">
							<div className="flex flex-col gap-2.5 md:gap-4">
								<h1 className="text-[#070027] max-md:text-sm font-domine font-bold">
									{t(tKey('labels.welcome'))} {auth.fname + ' ' + auth.lname}!
								</h1>
								<p className="text-[#070027] text-xs md:text-sm">
									{t(tKey('labels.uniqueBlocks'))}
								</p>
							</div>
							<img src={CalendarImage} className="max-md:h-14" />
						</div>
						<div className="flex flex-col gap-3">
							<div className="flex items-center justify-between">
								<h1 className="text-primary max-md:text-sm font-domine font-bold">
									{t(tKey('titles.totalBookings'))}
								</h1>
								<TabGroup>
									<TabList className="border border-[#5964D3] bg-white grid grid-cols-2 rounded-full">
										<Tab
											onClick={() => setSelectedStat('Appointment')}
											className="rounded-l-full overflow-hidden border-r border-[#5964D3] whitespace-nowrap text-ellipsis py-1 px-1.5 md:px-4 text-xs md:text-sm font-semibold text-[#070027CC] focus:outline-none data-[selected]:text-[#5964D3] data-[hover]:text-[#5964D3] data-[selected]:bg-[#5964D333] data-[hover]:bg-[#5964D333] data-[selected]:data-[hover]:bg-[#5964D333]">
											{t(tKey('labels.appointments'))}
										</Tab>
										<Tab
											onClick={() => setSelectedStat('Event')}
											className="rounded-r-full overflow-hidden whitespace-nowrap text-ellipsis py-1 px-1.5 md:px-4 text-xs md:text-sm font-semibold text-[#070027CC] focus:outline-none data-[selected]:text-[#5964D3] data-[hover]:text-[#5964D3] data-[selected]:bg-[#5964D333] data-[hover]:bg-[#5964D333] data-[selected]:data-[hover]:bg-[#5964D333]">
											{t(tKey('labels.events'))}
										</Tab>
									</TabList>
								</TabGroup>
							</div>

							<div
								className={clsx(
									'md:grid flex items-center gap-3 md:gap-6 max-md:overflow-x-auto',
									selectedStat === 'Event' ? 'md:grid-cols-3' : 'md:grid-cols-4'
								)}>
								{selectedStat === 'Event'
									? eventStats.map((eventStat, index) => (
											<StatsCard
												key={eventStat.title}
												title={eventStat.title}
												value={eventStat.value}
												total={eventStats[0]?.value}
												icon={eventStat.icon}
												link={eventStat.link}
												className={clsx(
													index === 0
														? 'bg-[#7094F4]'
														: index === 1
															? 'bg-[#BF943D]'
															: 'bg-[#38A738]'
												)}
											/>
										))
									: appointmentStats.map((appointmentStat, index) => (
											<StatsCard
												key={appointmentStat.title}
												title={appointmentStat.title}
												value={appointmentStat.value}
												total={appointmentStats[0].value}
												icon={appointmentStat.icon}
												link={appointmentStat.link}
												className={clsx(
													index === 0
														? 'bg-[#7094F4]'
														: index === 1
															? 'bg-[#BF943D]'
															: index === 2
																? 'bg-[#BE454B]'
																: 'bg-[#38A738]'
												)}
											/>
										))}
							</div>
						</div>

						<div className="flex max-md:flex-col items-start gap-4">
							<div
								style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
								className="border border-[#DBE5FA] self-stretch md:basis-1/3 flex flex-col gap-y-[18px] md:gap-y-[22px] rounded-2xl md:rounded-[20px] bg-white p-3 md:p-3.5 md:py-6 md:px-[19px]">
								<h1 className="text-[#001E00] max-md:text-sm font-domine font-bold">
									{t(tKey('titles.bookingsOverview'))}
								</h1>
								<div className="flex md:flex-col gap-4">
									<div className="rounded-full max-md:basis-1/2 border border-[#DBE5FA] bg-white py-1.5 md:py-3 px-6 max-md:flex-col flex max-md:gap-[5px] justify-between md:items-center">
										<div className="flex items-center gap-[5px]">
											<h2 className="text-[#001E00] font-semibold text-sm">
												{t(tKey('labels.events'))}
											</h2>
											<div className="bg-[#13BAB4] max-md:hidden size-8 flex items-center justify-center rounded-md">
												<p className="italic text-xs text-white">{eventStats[2]?.value}</p>
											</div>
										</div>
										<div className="flex items-center self-stretch justify-between">
											<div className="bg-[#13BAB4] md:hidden size-6 flex items-center justify-center rounded-md">
												<p className="italic text-xs text-white">{eventStats[2]?.value}</p>
											</div>
											<PieChart
												width={32}
												height={32}
												margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
												<Pie
													data={eventsdata}
													cx="50%"
													cy="50%"
													innerRadius={10}
													outerRadius={15}
													startAngle={90}
													endAngle={450}
													stroke="none"
													fill="#8884d8"
													dataKey="value">
													{eventsdata.map((entry, index) => (
														<Cell
															key={`cell-${index}`}
															fill={EVENT_COLORS[index % EVENT_COLORS.length]}
														/>
													))}
												</Pie>
											</PieChart>
										</div>
									</div>
									<div className="rounded-full max-md:basis-1/2 border border-[#DBE5FA] bg-white py-1.5 md:py-3 px-6 flex max-md:flex-col max-md:gap-[5px] justify-between md:items-center">
										<div className="flex items-center gap-[5px]">
											<h2 className="text-[#001E00] font-semibold text-sm">
												{t(tKey('labels.appointments'))}
											</h2>
											<div className="bg-[#5964D3] max-md:hidden size-8 flex items-center justify-center rounded-md">
												<p className="italic text-xs text-white">{appointmentStats[3]?.value}</p>
											</div>
										</div>
										<div className="flex items-center self-stretch justify-between">
											<div className="bg-[#5964D3] md:hidden size-6 flex items-center justify-center rounded-md">
												<p className="italic text-xs text-white">{appointmentStats[3]?.value}</p>
											</div>
											<PieChart
												width={32}
												height={32}
												margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
												<Pie
													data={appointmentsdata}
													cx="50%"
													cy="50%"
													innerRadius={10}
													outerRadius={15}
													startAngle={90}
													endAngle={450}
													stroke="none"
													fill="#8884d8"
													dataKey="value">
													{appointmentsdata.map((entry, index) => (
														<Cell
															key={`cell-${index}`}
															fill={APPOINTMENT_COLORS[index % APPOINTMENT_COLORS.length]}
														/>
													))}
												</Pie>
											</PieChart>
										</div>
									</div>
								</div>
							</div>
							<div
								style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
								className="border border-[#DBE5FA] md:basis-2/3 max-md:w-full flex flex-col rounded-[20px] bg-white p-3 md:pt-3.5 md:pb-[18px] md:px-[18px]">
								<div className="flex items-center justify-between">
									<h1 className="text-[#001E00] font-domine max-md:text-sm font-bold">
										{t(tKey('titles.totalRevenue'))}
									</h1>
									<h1 className="text-primary-light font-domine text-sm font-bold md:text-[18px]">
										{selectedRange === 'monthly'
											? weeklyRevenue[0]?.currency +
												' ' +
												new Intl.NumberFormat(undefined, {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2
												}).format(weeklyRevenue?.reduce((agg, curr) => agg + curr.revenue, 0))
											: monthlyRevenue[0]?.currency +
												' ' +
												new Intl.NumberFormat(undefined, {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2
												}).format(monthlyRevenue?.reduce((agg, curr) => agg + curr.revenue, 0))}
									</h1>
								</div>
								<div className="flex justify-end my-1 items-center gap-x-1 md:gap-x-2">
									<MenuDropdown
										options={[t(tKey('menu.month')), t(tKey('menu.year'))]}
										value={
											selectedRange === 'monthly' ? t(tKey('menu.month')) : t(tKey('menu.year'))
										}
										shouldIgnore
										className="!left-0"
										onChange={(value: string) => {
											if (value === t(tKey('menu.month'))) {
												setSelectedRange('monthly')
											} else {
												setSelectedRange('yearly')
											}
										}}
									/>
									{selectedRange === 'yearly' && (
										<Combobox
											value={selectedDuration}
											onChange={(value: string) => setSelectedDuration(value)}>
											<div className="relative">
												<div className="relative cursor-default w-28 md:w-32 overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
													<Combobox.Input
														placeholder={t(tKey('placeholders.year'))}
														className="border border-border max-md:text-xs pr-7 w-full !py-[6px] !md:py-[10px] rounded focus:border-primary-light text-sm leading-5 text-gray-900 focus:ring-0"
														displayValue={option => selectedDuration}
														onChange={event => setQuery(event.target.value)}
													/>

													<Combobox.Button className="absolute inset-y-0 flex items-center right-0 pr-2">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none">
															<path
																d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
																fill="#123258"
															/>
														</svg>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0">
													<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
														{Array.isArray(getYears()) &&
															filteredOptions.map((option, index) => (
																<Combobox.Option
																	key={index}
																	className={({ active }) =>
																		`relative cursor-default select-none py-0.5 ${
																			active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
																		}`
																	}
																	value={option}>
																	{({ selected, active }) => (
																		<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
																			<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
																				{option}
																			</span>
																		</span>
																	)}
																</Combobox.Option>
															))}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									)}
								</div>
								<ResponsiveContainer width="100%" height={isMobile ? 200 : 200}>
									<AreaChart
										width={600}
										height={300}
										data={
											selectedRange === 'monthly'
												? weeklyRevenue.map(revenue => ({
														name: revenue.dayOfWeek,
														revenue: revenue.revenue
													}))
												: monthlyRevenue.map(revenue => ({
														name: revenue.month,
														revenue: revenue.revenue
													}))
										}
										margin={{
											top: 10,
											right: 30,
											left: 0,
											bottom: 0
										}}>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis dataKey="name" />
										<YAxis />
										<Tooltip />
										<Area type="monotone" dataKey="revenue" stroke="#7094F4" fill="#7094F424" />
									</AreaChart>
								</ResponsiveContainer>
							</div>
						</div>

						<div className="flex md:hidden flex-col gap-3">
							<div className="flex items-center justify-between">
								<h1 className="text-primary font-domine text-sm font-bold">
									{t(tKey('titles.upcomingAppointments'))}
								</h1>
								<Link className="text-[#5964D3] font-bold text-[11px]" to="/appointments">
									{t(tKey('viewAll'))}
								</Link>
							</div>
							<div className="flex flex-col gap-3">
								{companyAppointments.filter(
									appointment =>
										DateTime.fromMillis(appointment.from) > DateTime.now() &&
										appointment.status !== AppointmentStatus.CANCELLED
								).length > 0 ? (
									companyAppointments
										.filter(appointment => DateTime.fromMillis(appointment.from) > DateTime.now())
										.slice(0, 3)
										.sort((a, b) => a.from - b.from)
										.map(appointment => (
											<div
												key={appointment._id}
												style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
												onClick={() => navigate(`/appointment-details/${appointment._id}`)}
												className="flex items-center cursor-pointer border border-[#DBE5FA] bg-white p-2 pr-3 rounded-full">
												<div className="rounded-full bg-[#D69C8680] p-2 flex flex-col items-center gap-[3px]">
													<p className="text-primary font-domine text-sm">
														{DateTime.fromMillis(appointment.from).toFormat('dd')}
													</p>
													<p className="text-primary font-semibold text-[11px] text-center">
														{DateTime.fromMillis(appointment.from).toFormat('LLL,yyyy')}
													</p>
												</div>
												<div className="px-2 flex flex-col gap-0.5">
													<h1 className="text-primary font-medium text-sm">
														{appointment.service_name}
													</h1>
													<p className="text-[#4C6780] font-medium text-xs">
														{appointment.id_client.fname + ' ' + appointment.id_client.lname}
													</p>
													<div className="flex items-center gap-2 mt-0.5">
														<ClockIcon className="size-4 text-primary" />
														<p className="text-primary text-xs">
															{DateTime.fromMillis(appointment.from).toFormat('hh:mm a')}
														</p>
													</div>
												</div>
											</div>
										))
								) : (
									<p className="text-tertiary text-xs">{t(tKey('noAppointments'))}</p>
								)}
							</div>
						</div>

						<div
							style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
							className="rounded-2xl md:rounded-[32px] bg-white border border-[#DBE5FA] p-3 md:p-6 flex items-start justify-between">
							<div className="flex flex-col max-md:w-full gap-4 md:gap-5 self-stretch">
								<h1 className="text-[#001E00] font-domine max-md:text-sm font-bold">
									{t(tKey('titles.tasksList'))}
								</h1>
								<div className="flex md:hidden gap-3 items-center self-stretch">
									<button
										onClick={() => setSelectedTaskType(t(tKey('labels.backlog')))}
										className={clsx(
											'rounded-[6px] whitespace-nowrap h-6 w-full justify-center py-1 px-3 hover:text-white hover:bg-[#ED1649] font-bold text-xs flex items-center gap-1',
											selectedTaskType === t(tKey('labels.backlog'))
												? 'bg-[#ED1649] text-white'
												: 'bg-[#ED164929] text-[#ED1649]'
										)}>
										{t(tKey('labels.backlog'))}{' '}
										<span className="text-[11px]">
											{tasksByType[t(tKey('labels.backlog'))].length}
										</span>
									</button>
									<button
										onClick={() => setSelectedTaskType(t(tKey('labels.inProgress')))}
										className={clsx(
											'rounded-[6px] whitespace-nowrap h-6 w-full justify-center py-1 px-3 hover:text-white hover:bg-[#0F8CE9] font-bold text-xs flex items-center gap-1',
											selectedTaskType === t(tKey('labels.inProgress'))
												? 'bg-[#0F8CE9] text-white'
												: 'bg-[#0F8CE929] text-[#0F8CE9]'
										)}>
										{t(tKey('labels.inProgress'))}{' '}
										<span className="text-[11px]">
											{tasksByType[t(tKey('labels.inProgress'))].length}
										</span>
									</button>
									<button
										onClick={() => setSelectedTaskType(t(tKey('labels.completed')))}
										className={clsx(
											'rounded-[6px] whitespace-nowrap h-6 w-full justify-center py-1 px-3 hover:bg-[#61BC5F] hover:text-white font-bold text-xs flex items-center gap-1',
											selectedTaskType === t(tKey('labels.completed'))
												? 'bg-[#61BC5F] text-white'
												: 'bg-[#61BC5F29] text-[#61BC5F]'
										)}>
										{t(tKey('labels.completed'))}{' '}
										<span className="text-[11px]">
											{tasksByType[t(tKey('labels.completed'))].length}
										</span>
									</button>
								</div>
								<div className="flex flex-col grow gap-y-3.5">
									{tasksByType[selectedTaskType]?.length > 0 ? (
										<div className="flex flex-col h-full justify-between">
											<div className="flex flex-col gap-y-3.5">
												{tasksByType[selectedTaskType]?.slice(0, 4).map(task => (
													<div
														key={task._id}
														onClick={() => navigate(`/task-manager/create/${task._id}`)}
														className="flex cursor-pointer items-center gap-3">
														<TaskListIcon
															className={clsx('max-md:size-3.5', {
																'text-[#FF3A44]': selectedTaskType === t(tKey('labels.backlog')),
																'text-[#316BD6]': selectedTaskType === t(tKey('labels.inProgress')),
																'text-[#61BC5F]': selectedTaskType === t(tKey('labels.completed'))
															})}
														/>
														<p className="text-[#001E00] font-medium text-xs md:text-sm">
															{task.taskName}
														</p>
													</div>
												))}
											</div>
											<Link
												to="/task-manager"
												className="text-[#5964D3] text-xs md:text-sm font-bold">
												{t(tKey('viewAll'))}
											</Link>
										</div>
									) : (
										<p className="text-tertiary text-xs md:text-sm">{t(tKey('noTasks'))}</p>
									)}
								</div>
							</div>
							<div className="flex flex-col max-md:hidden gap-4 self-stretch">
								<button
									onClick={() => setSelectedTaskType(t(tKey('labels.backlog')))}
									className={clsx(
										'rounded-[20px] h-[53px] w-[133px] justify-center py-1 px-3 hover:text-white hover:bg-[#ED1649] font-bold text-sm flex items-center gap-1',
										selectedTaskType === t(tKey('labels.backlog'))
											? 'bg-[#ED1649] text-white'
											: 'bg-[#ED164929] text-[#ED1649]'
									)}>
									{t(tKey('labels.backlog'))}{' '}
									<span className="text-lg">{tasksByType[t(tKey('labels.backlog'))].length}</span>
								</button>
								<button
									onClick={() => setSelectedTaskType(t(tKey('labels.inProgress')))}
									className={clsx(
										'rounded-[20px] h-[53px] w-[133px] justify-center py-1 px-3 hover:text-white hover:bg-[#0F8CE9] font-bold text-sm flex items-center gap-1',
										selectedTaskType === t(tKey('labels.inProgress'))
											? 'bg-[#0F8CE9] text-white'
											: 'bg-[#0F8CE929] text-[#0F8CE9]'
									)}>
									{t(tKey('labels.inProgress'))}{' '}
									<span className="text-lg">
										{tasksByType[t(tKey('labels.inProgress'))].length}
									</span>
								</button>
								<button
									onClick={() => setSelectedTaskType(t(tKey('labels.completed')))}
									className={clsx(
										'rounded-[20px] h-[53px] w-[133px] justify-center py-1 px-3 hover:bg-[#61BC5F] hover:text-white font-bold text-sm flex items-center gap-1',
										selectedTaskType === t(tKey('labels.completed'))
											? 'bg-[#61BC5F] text-white'
											: 'bg-[#61BC5F29] text-[#61BC5F]'
									)}>
									{t(tKey('labels.completed'))}{' '}
									<span className="text-lg">{tasksByType[t(tKey('labels.completed'))].length}</span>
								</button>
							</div>
						</div>
					</div>
					<div className="md:basis-[25%] basis-full max-md:w-full md:rounded-tl-[20px] md:rounded-bl-[20px] flex flex-col gap-4 md:gap-6 md:mt-6 max-md:p-4 max-md:pt-0 md:p-6 md:bg-white">
						<Calendar
							onChange={setCalendarValue}
							value={calendarValue}
							selectRange={false}
							prevLabel={
								<div className="size-5 ml-auto flex items-center justify-center rounded-full bg-[#A4BDC7] p-2">
									<ChevronLeftIcon className="size-4 shrink-0 text-white" />
								</div>
							}
							nextLabel={
								<div className="size-5 mr-auto flex items-center justify-center rounded-full bg-[#A4BDC7] p-2">
									<ChevronRightIcon className="size-4 shrink-0 text-white" />
								</div>
							}
							className="custom-calendar max-md:hidden shadow-none bg-[#F0F1F8] rounded-[20px]"
							next2Label={null}
							prev2Label={null}
							goToRangeStartOnSelect={false}
							tileClassName={({ date }) => {
								const currentDate = DateTime.fromJSDate(date)
								const isSelected = DateTime.fromJSDate(calendarValue as any)?.hasSame(
									currentDate,
									'day'
								)

								if (isSelected) {
									return 'text-white text-xs'
								}

								return 'text-primary text-xs'
							}}
							locale={i18n.language}
							formatShortWeekday={(locale, date) =>
								date.toLocaleDateString(locale, { weekday: 'narrow' })
							}
						/>
						<div className="flex max-md:hidden flex-col gap-3">
							<div className="flex items-center justify-between">
								<h1 className="text-primary font-domine font-bold">
									{t(tKey('titles.upcomingAppointments'))}
								</h1>
								<Link className="text-[#5964D3] font-bold text-[11px]" to="/appointments">
									{t(tKey('viewAll'))}
								</Link>
							</div>
							<div className="flex flex-col gap-3">
								{companyAppointments.filter(
									appointment =>
										DateTime.fromMillis(appointment.from) > DateTime.now() &&
										appointment.status !== AppointmentStatus.CANCELLED
								).length > 0 ? (
									companyAppointments
										.filter(appointment => DateTime.fromMillis(appointment.from) > DateTime.now())
										.slice(0, 3)
										.sort((a, b) => a.from - b.from)
										.map(appointment => (
											<div
												key={appointment._id}
												onClick={() => navigate(`/appointment-details/${appointment._id}`)}
												style={{ boxShadow: '0px 4px 24px 0px #CFD8DCBD' }}
												className="flex items-center cursor-pointer border border-[#DBE5FA] bg-white p-2 pr-3 rounded-full">
												<div className="rounded-full bg-[#D69C8680] p-2 flex flex-col items-center gap-[3px]">
													<p className="text-primary font-domine text-sm">
														{DateTime.fromMillis(appointment.from).toFormat('dd')}
													</p>
													<p className="text-primary font-semibold text-[11px] text-center">
														{DateTime.fromMillis(appointment.from).toFormat('LLL,yyyy')}
													</p>
												</div>
												<div className="px-2 flex flex-col gap-0.5">
													<h1 className="text-primary font-medium text-sm">
														{appointment.service_name}
													</h1>
													<p className="text-[#4C6780] font-medium text-xs">
														{appointment.id_client.fname + ' ' + appointment.id_client.lname}
													</p>
													<div className="flex items-center gap-2 mt-0.5">
														<ClockIcon className="size-4 text-primary" />
														<p className="text-primary text-xs">
															{DateTime.fromMillis(appointment.from).toFormat('hh:mm a')}
														</p>
													</div>
												</div>
											</div>
										))
								) : (
									<p className="text-tertiary text-sm">{t(tKey('noAppointments'))}</p>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-3">
							<div className="flex items-center justify-between">
								<h1 className="text-primary max-md:text-sm font-domine font-bold">
									{t(tKey('titles.upcomingEvents'))}
								</h1>
								<Link className="text-[#5964D3] font-bold text-[11px]" to="/events">
									{t(tKey('viewAll'))}
								</Link>
							</div>
							<div className="flex flex-col gap-3">
								{events.filter(event => DateTime.fromMillis(event.from) > DateTime.now()).length >
								0 ? (
									events
										.filter(event => DateTime.fromMillis(event.from) > DateTime.now())
										.slice(0, 3)
										.sort((a, b) => a.from - b.from)
										.map(event => (
											<div
												key={event._id}
												onClick={() => navigate('/events', { state: event._id })}
												className="flex items-center cursor-pointer border border-[#DBE5FA] bg-white md:bg-[#D3E3F180] py-2.5 px-2 rounded-xl gap-2">
												<TaskListIcon className="text-[#B790D8] size-3.5" />
												<div className="flex flex-col gap-px">
													<h2 className="text-primary font-semibold text-sm">{event.title}</h2>
													<div className="flex items-center gap-3">
														<div className="flex items-center gap-1">
															<TotalIcon className="size-4" />
															<p className="text-[#4C6780] text-xs">
																{DateTime.fromMillis(event.from).toFormat('LLL dd,yyyy')}
															</p>
														</div>
														<div className="flex items-center gap-1">
															<ClockIcon className="size-4 text-[#4C6780]" />
															<p className="text-[#4C6780] text-xs">
																{DateTime.fromMillis(event.from).toFormat('hh:mm a')} -{' '}
																{DateTime.fromMillis(event.to).toFormat('hh:mm a')}
															</p>
														</div>
													</div>
												</div>
											</div>
										))
								) : (
									<p className="text-tertiary text-xs md:text-sm">{t(tKey('noEvents'))}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}
