import { ChevronLeftIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { NoInboxIcon, NoMessagesIcon } from 'assets/icons'
import clsx from 'clsx'
import { Spinner } from 'components/animations/spinner'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import { Tabs } from 'components/app/tabs'
import {
	addDoc,
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	updateDoc,
	where
} from 'firebase/firestore'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import employeeService from 'services/employee-service'
import userService from 'services/user-service'
import { getChatDuration } from 'utils/date'
import { getTKey } from 'utils/language'
import { db } from '../../firebase/config'

enum ChatOptions {
	CLIENTS = 'clients',
	STAFF = 'staff'
}

export const Chats = () => {
	const isMobile = useIsMobile()
	const { t } = useTranslation()
	const tKey = getTKey('chats')

	const auth = useAppSelector(state => state.auth)

	const chatMessagesRef = useRef<HTMLDivElement>(null)
	const lastMessageRef = useRef<HTMLDivElement>(null)

	const [clients, setClients] = useState<User[]>([])
	const [employees, setEmployees] = useState<Employee[]>([])
	const [activeTab, setActiveTab] = useState<ChatOptions>(ChatOptions.CLIENTS)
	const [searchText, setSearchText] = useState<string>()
	const [chats, setChats] = useState<Chat[]>([])
	const [isChatLoaded, setIsChatLoaded] = useState(false)
	const [chatMessages, setChatMessages] = useState<ChatMessage[]>([])
	const [newMessage, setNewMessage] = useState('')
	const [selectedUser, setSelectedUser] = useState<User>()
	const [selectedChat, setSelectedChat] = useState<Chat>()

	useEffect(() => {
		if (chatMessages.length > 0) {
			lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [chatMessages])

	useEffect(() => {
		userService.getCompanyUsers().then(res => setClients(res.users))
		employeeService.getCompanyEmployees().then(res => setEmployees(res))
	}, [])

	useEffect(() => {
		if (!auth._id) return

		let isChats1Loaded = false
		let isChats2Loaded = false

		const checkIfChatsLoaded = () => {
			if (isChats1Loaded && isChats2Loaded) {
				setIsChatLoaded(true)
			}
		}

		const q1 = query(collection(db, 'chats'), where('participantOne.participantId', '==', auth._id))
		const q2 = query(collection(db, 'chats'), where('participantTwo.participantId', '==', auth._id))

		const unsubscribe1 = onSnapshot(q1, querySnapshot => {
			const chatsData = querySnapshot.docs.map(
				doc =>
					({
						id: doc.id,
						...doc.data()
					}) as Chat
			)

			setChats(prevChats => {
				const updatedChats = [...prevChats]
				chatsData.forEach(newChat => {
					const existingIndex = updatedChats.findIndex(chat => chat.id === newChat.id)
					if (existingIndex >= 0) {
						updatedChats[existingIndex] = newChat
					} else {
						updatedChats.push(newChat)
					}
				})
				return updatedChats
			})

			isChats1Loaded = true
			checkIfChatsLoaded()
		})

		const unsubscribe2 = onSnapshot(q2, querySnapshot => {
			const chatsData = querySnapshot.docs.map(
				doc =>
					({
						id: doc.id,
						...doc.data()
					}) as Chat
			)

			setChats(prevChats => {
				const updatedChats = [...prevChats]
				chatsData.forEach(newChat => {
					const existingIndex = updatedChats.findIndex(chat => chat.id === newChat.id)
					if (existingIndex >= 0) {
						updatedChats[existingIndex] = newChat
					} else {
						updatedChats.push(newChat)
					}
				})
				return updatedChats
			})

			isChats2Loaded = true
			checkIfChatsLoaded()
		})

		return () => {
			unsubscribe1()
			unsubscribe2()
		}
	}, [auth._id])

	const getChatMessages = (chat: Chat) => {
		if (!chat.id) {
			console.error('Chat ID is undefined. Cannot fetch chat messages.')
			return
		}

		setSelectedChat(chat)

		const q = query(
			collection(db, 'chats', chat.id, 'chatMessages'),
			orderBy('createdTimestamp', 'asc')
		)

		const unsubscribe = onSnapshot(q, querySnapshot => {
			const messagesList = querySnapshot.docs.map(
				doc =>
					({
						id: doc.id,
						...doc.data()
					}) as ChatMessage
			)

			setChatMessages(messagesList)
		})

		return () => {
			unsubscribe()
		}
	}

	const markAsRead = useCallback(
		(chatId: string) => {
			updateDoc(doc(db, 'chats', chatId), {
				[`unreadMessages.${auth._id}`]: false
			})
		},
		[auth._id]
	)

	const sendMessage = async (event: React.FormEvent) => {
		if (event) event.preventDefault()

		if (!selectedChat || !selectedChat.id || !newMessage.trim()) return

		const messageToSend = newMessage
		setNewMessage('')

		const messagesCollectionRef = collection(db, 'chats', selectedChat.id, 'chatMessages')
		const newMessageObject: Omit<ChatMessage, 'id'> = {
			chatId: selectedChat.id,
			senderId: auth._id,
			messageText: messageToSend,
			senderModel: activeTab === ChatOptions.CLIENTS ? 'clients' : 'User',
			createdTimestamp: serverTimestamp()
		}
		await addDoc(messagesCollectionRef, newMessageObject)

		const receiverId =
			selectedChat.participantOne.participantId === auth._id
				? selectedChat.participantTwo.participantId
				: selectedChat.participantOne.participantId

		await updateDoc(doc(db, 'chats', selectedChat.id), {
			[`unreadMessages.${receiverId}`]: true,
			lastMessage: {
				messageText: messageToSend
			},
			updated: serverTimestamp()
		})
	}

	const createChat = useCallback(
		async (user: User | Employee) => {
			const existingChat = chats.find(
				(chat: Chat) =>
					(chat.participantOne.participantId === auth._id &&
						chat.participantTwo.participantId === user._id) ||
					(chat.participantOne.participantId === user._id &&
						chat.participantTwo.participantId === auth._id)
			)

			if (existingChat) {
				setSelectedChat(existingChat)
				getChatMessages(existingChat)
				setSelectedUser(user as User)
				return
			}

			const newChat: Omit<Chat, 'id'> = {
				participantOne: { participantId: auth._id, onModel: 'User' },
				participantTwo: {
					participantId: user._id,
					onModel: activeTab === ChatOptions.CLIENTS ? 'clients' : 'User'
				},
				companyId: auth.companyId,
				companyName: auth.company.company_name,
				created: serverTimestamp(),
				updated: serverTimestamp(),
				unreadMessages: {
					[auth._id]: false,
					[user._id]: true
				},
				lastMessage: { messageText: '' }
			}

			const chatDocRef = await addDoc(collection(db, 'chats'), newChat)
			const createdChat: Chat = { id: chatDocRef.id, ...newChat }

			// setChats((currentChats: Chat[]) => [...currentChats, createdChat])
			setSelectedChat(createdChat)
			setSearchText('')
			getChatMessages(createdChat)
			setSelectedUser(user as User)
			toast.success('Chat created successfully')
		},
		[chats, auth, activeTab]
	)

	const { filteredChats } = useMemo(() => {
		const filteredChats = (
			(activeTab === ChatOptions.CLIENTS ? clients : employees) as any[]
		)?.reduce((prev, curr) => {
			if (searchText) {
				const fullName = curr.fname + ' ' + curr.lname
				if (fullName.toLowerCase().includes(searchText.toLowerCase())) {
					return [...prev, curr]
				}
			}
			return prev
		}, [] as any[])
		return { filteredChats }
	}, [clients, employees, searchText])

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.chats'))}>
			<div className="flex bg-background min-h-[90vh] md:p-6 gap-6">
				{!isChatLoaded ? (
					<div className="fixed left-1/2 top-1/2 flex flex-col gap-y-2 items-center">
						<Spinner className=" h-6 w-6 " />
						<p className="text-base md:text-lg">Loading...</p>
					</div>
				) : (
					<>
						<div
							style={{ boxShadow: '0px 4px 24px 0px #001F3F14' }}
							className="py-6 bg-white max-md:!shadow-none px-[21px] flex flex-col gap-6 basis-full md:basis-[35%] max-md:border-t md:border border-[#CFD8DC80] md:rounded-xl">
							<div className="flex items-center justify-between">
								<h2 className="font-domine font-bold text-primary">
									{isMobile ? t(tKey('titles.chats')) : t(tKey('titles.message'))}
								</h2>
								<div className="md:hidden">
									<Tabs
										tabs={[ChatOptions.CLIENTS, ChatOptions.STAFF]}
										selectedTab={activeTab}
										setTab={(tab: string) => {
											setActiveTab(tab as ChatOptions)
											setSelectedChat(undefined)
											setSearchText('')
										}}
										className="!pb-1 !w-full max-md:!text-base max-md:!font-normal"
									/>
								</div>
							</div>
							<div className="relative flex items-center justify-end">
								<input
									style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
									type="text"
									placeholder={t(tKey('placeholders.keyword'))}
									value={searchText}
									onChange={e => setSearchText(e.target.value)}
									className="px-4 py-2 w-full placeholder:text-[#7F9AB2] text-primary focus:border-primary border border-[#CFD8DC] rounded-md"
								/>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									className="absolute -translate-x-3">
									<g clipPath="url(#clip0_2878_8260)">
										<path
											d="M15.4996 14.5006H14.7096L14.4296 14.2306C15.6296 12.8306 16.2496 10.9206 15.9096 8.89063C15.4396 6.11063 13.1196 3.89063 10.3196 3.55063C6.08965 3.03063 2.52965 6.59063 3.04965 10.8206C3.38965 13.6206 5.60965 15.9406 8.38965 16.4106C10.4196 16.7506 12.3296 16.1306 13.7296 14.9306L13.9996 15.2106V16.0006L18.2496 20.2506C18.6596 20.6606 19.3296 20.6606 19.7396 20.2506C20.1496 19.8406 20.1496 19.1706 19.7396 18.7606L15.4996 14.5006ZM9.49965 14.5006C7.00965 14.5006 4.99965 12.4906 4.99965 10.0006C4.99965 7.51063 7.00965 5.50063 9.49965 5.50063C11.9896 5.50063 13.9996 7.51063 13.9996 10.0006C13.9996 12.4906 11.9896 14.5006 9.49965 14.5006Z"
											fill="#123258"
										/>
									</g>
									<defs>
										<clipPath id="clip0_2878_8260">
											<rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
										</clipPath>
									</defs>
								</svg>
							</div>
							<div className="w-full max-md:hidden">
								<Tabs
									tabs={[ChatOptions.CLIENTS, ChatOptions.STAFF]}
									selectedTab={activeTab}
									setTab={(tab: string) => {
										setSearchText('')
										setSelectedChat(undefined)
										setActiveTab(tab as ChatOptions)
									}}
									className="!pb-2 !w-full"
								/>
							</div>
							<div className="flex flex-col gap-3">
								{searchText
									? filteredChats
											.filter((chat: User) => chat._id !== auth._id)
											.sort((a: User, b: User) => a.fname.localeCompare(b.fname))
											.map((chat: User) => (
												<div
													onClick={() => createChat(chat)}
													key={chat._id}
													className="flex cursor-pointer justify-between">
													<div className="flex gap-x-3 items-start">
														{chat.profile_url === 'crm' ? (
															<div className="rounded-full h-6 w-6 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
																<p
																	style={{
																		color:
																			'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
																	}}>
																	{chat.fname.slice(0, 1)}
																</p>
															</div>
														) : (
															<img src={chat.profile_url} className="h-6 w-6 rounded-full" />
														)}
														<div className="flex flex-col gap-1">
															<h5
																style={{
																	color:
																		'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
																}}
																className="font-domine font-bold text-sm">
																{chat.fname + ' ' + chat.lname}
															</h5>
															<p
																className={clsx(
																	' text-[11px]',
																	activeTab === ChatOptions.CLIENTS
																		? 'text-[#0F8CE9]'
																		: 'text-[#06AB91]'
																)}>
																{activeTab === ChatOptions.CLIENTS
																	? t(tKey('labels.client'))
																	: t(tKey('labels.staff'))}
															</p>
														</div>
													</div>
												</div>
											))
									: chats?.map((chat: Chat) => {
											const receiver = (
												(activeTab === ChatOptions.CLIENTS ? clients : employees) as User[]
											)?.find(user =>
												auth._id === chat.participantOne.participantId
													? user._id === chat.participantTwo.participantId
													: user._id === chat.participantOne.participantId
											)

											if (!receiver) return ''

											return (
												<div
													key={chat.id}
													className="cursor-pointer flex items-start justify-between"
													onClick={() => {
														setSelectedUser(receiver)
														getChatMessages(chat)
														if (chat.unreadMessages[auth._id]) {
															markAsRead(chat.id)
														}
													}}>
													<div className="flex items-center gap-x-3">
														{receiver?.profile_url === 'crm' ? (
															<div className="shrink-0 rounded-full h-8 w-8 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
																<p
																	style={{
																		color:
																			'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
																	}}>
																	{receiver?.fname.slice(0, 1)}
																</p>
															</div>
														) : (
															<img
																src={receiver?.profile_url}
																alt="user"
																className="h-8 w-8 shrink-0 rounded-full"
															/>
														)}

														<div className="flex flex-col gap-y-1">
															<h4 className="font-domine font-bold text-sm">
																{receiver?.fname + ' ' + receiver?.lname}
															</h4>

															{chat.lastMessage?.messageText && (
																<p
																	className={clsx(
																		'text-[11px] overflow-hidden text-ellipsis whitespace-nowrap text-[#9E9E9E]',
																		{
																			'font-bold !text-gray-600': chat.unreadMessages[auth._id]
																		}
																	)}>
																	{chat.lastMessage.messageText.length > 38
																		? chat.lastMessage.messageText.slice(0, 38) + '...'
																		: chat.lastMessage.messageText}
																</p>
															)}
														</div>
													</div>
													<div className="flex gap-y-1 items-end flex-col justify-between">
														<p className="text-[#9E9E9E] whitespace-nowrap text-[10px] ">
															{getChatDuration(chat.updated)} {t(tKey('labels.ago'))}
														</p>
														{chat.unreadMessages[auth._id] && (
															<div className="p-0.5 bg-[#5A6555] h-2.5 w-2.5 rounded-full flex items-center justify-center" />
														)}
													</div>
												</div>
											)
										})}
							</div>
						</div>
						{isMobile && selectedChat && (
							<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
								<div className="sticky flex items-center gap-x-6 top-0 z-10 bg-white p-5 pb-4 pt-6 border-b border-border">
									<ChevronLeftIcon
										onClick={() => setSelectedChat(undefined)}
										className="w-3 h-3 md:hidden cursor-pointer"
									/>
									<div className="flex gap-3">
										{selectedUser?.profile_url === 'crm' ? (
											<div className="rounded-full h-10 w-10 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
												<p
													style={{
														color:
															'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
													}}
													className="text-lg">
													{selectedUser.fname.slice(0, 1)}
												</p>
											</div>
										) : (
											<img src={selectedUser?.profile_url} className="h-10 w-10 rounded-full" />
										)}
										<div className="flex flex-col gap-y-1">
											<h2 className="font-domine font-bold text-sm">
												{selectedUser?.fname + ' ' + selectedUser?.lname}
											</h2>
											<p
												className={clsx(
													'text-[11px]',
													activeTab === ChatOptions.CLIENTS ? 'text-[#0F8CE9]' : 'text-[#06AB91]'
												)}>
												{activeTab === ChatOptions.CLIENTS ? 'Client' : 'Staff'}
											</p>
										</div>
									</div>
								</div>

								<div
									ref={chatMessagesRef}
									className="h-[75vh] overflow-auto p-6 flex flex-col gap-y-3">
									{chatMessages.length > 0 ? (
										chatMessages.map((message: ChatMessage, index: number) => {
											const isMyMessage = auth._id === message.senderId
											const isLastMessage = index === chatMessages.length - 1

											return (
												<div
													key={message.id}
													className="flex flex-col gap-y-[7px]"
													ref={isLastMessage ? lastMessageRef : null}>
													<div
														className={clsx(
															'py-1.5 w-fit px-6 flex justify-center rounded-xl',
															!isMyMessage
																? 'bg-[#CFD8DC80] text-[#323232]'
																: 'bg-[#5A6555] ml-auto text-white'
														)}>
														<p className="font-semibold text-xs ">{message.messageText}</p>
													</div>
													<p
														className={clsx('text-[10px] text-[#9E9E9E]', {
															'ml-auto': isMyMessage
														})}>
														{getChatDuration(message.createdTimestamp)} {t(tKey('labels.ago'))}
													</p>
												</div>
											)
										})
									) : (
										<div className="flex h-full flex-col items-center justify-center gap-y-7">
											<img src={NoMessagesIcon} />
											<div className="flex flex-col items-center gap-y-6">
												<h1 className="font-domine font-bold text-lg">
													{t(tKey('labels.noMessagesYet'))}
												</h1>
												<p className="text-sm text-center text-[#9E9E9E]">
													{t(tKey('labels.noInboxYet'))} <br />
													{t(tKey('labels.startChat'))}
												</p>
											</div>
										</div>
									)}
								</div>

								<form
									onSubmit={sendMessage}
									className="absolute p-6 w-full flex justify-end items-center bottom-0">
									<input
										required
										className="w-full focus:ring-0 focus:outline-none focus:border-primary-light py-4 pl-6 pr-12 border border-[#CFD8DC] rounded-xl bg-[#F5F7FA] text-sm font-semibold text-[#323232]"
										placeholder="Type a message"
										value={newMessage}
										onChange={e => setNewMessage(e.target.value)}
									/>
									<button
										type="submit"
										className="bg-white p-1.5 -translate-x-4 absolute h-[30px] w-[30px] rounded-full flex items-center justify-center">
										<PaperAirplaneIcon className="text-primary-light" />
									</button>
								</form>
							</Modal>
						)}
						{!isMobile && (
							<div
								style={{ boxShadow: '0px 4px 24px 0px #001F3F14' }}
								className="relative bg-white max-md:hidden grow flex flex-col gap-6 basis-[65%] border border-[#CFD8DC80] rounded-xl">
								{selectedChat && selectedUser && (
									<div className="w-full relative h-full">
										<div className="absolute bg-white rounded-t-xl w-full top-0 p-6 border-b border-[#CFD8DC80] flex items-center">
											{selectedUser && (
												<div className="flex gap-3">
													{selectedUser?.profile_url === 'crm' ? (
														<div className="rounded-full h-10 w-10 flex items-center justify-center text-sm font-domine bg-[#D3E3F1]">
															<p
																style={{
																	color:
																		'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
																}}
																className="text-lg">
																{selectedUser.fname.slice(0, 1)}
															</p>
														</div>
													) : (
														<img
															src={selectedUser?.profile_url}
															className="h-10 w-10 rounded-full"
														/>
													)}
													<div className="flex flex-col gap-y-1">
														<h2 className="font-domine font-bold text-sm">
															{selectedUser?.fname + ' ' + selectedUser?.lname}
														</h2>

														<p
															className={clsx(
																'text-[11px]',
																activeTab === ChatOptions.CLIENTS
																	? 'text-[#0F8CE9]'
																	: 'text-[#06AB91]'
															)}>
															{activeTab === ChatOptions.CLIENTS ? 'Client' : 'Staff'}
														</p>
													</div>
												</div>
											)}
										</div>
										<div
											ref={chatMessagesRef}
											className="px-[46px] h-[50vh] overflow-auto mt-24 py-6 flex flex-col gap-y-3">
											{chatMessages.length > 0 ? (
												chatMessages.map((message: ChatMessage, index: number) => {
													const isMyMessage = auth._id === message.senderId
													const isLastMessage = index === chatMessages.length - 1

													return (
														<div
															key={message.id}
															className="flex flex-col gap-y-[7px]"
															ref={isLastMessage ? lastMessageRef : null}>
															<div
																className={clsx(
																	'py-1.5 w-fit px-6 flex justify-center rounded-xl',
																	!isMyMessage
																		? 'bg-[#CFD8DC80] text-[#323232]'
																		: 'bg-[#5A6555] ml-auto text-white'
																)}>
																<p className="font-semibold text-xs ">{message.messageText}</p>
															</div>
															<p
																className={clsx('text-[10px] text-[#9E9E9E]', {
																	'ml-auto': isMyMessage
																})}>
																{getChatDuration(message.createdTimestamp)} {t(tKey('labels.ago'))}
															</p>
														</div>
													)
												})
											) : (
												<div className="flex h-full flex-col items-center justify-center gap-y-7">
													<img src={NoMessagesIcon} />
													<div className="flex flex-col items-center gap-y-6">
														<h1 className="font-domine font-bold text-lg">
															{t(tKey('labels.noMessagesYet'))}
														</h1>
														<p className="text-sm text-center text-[#9E9E9E]">
															{t(tKey('labels.noInboxYet'))} <br />
															{t(tKey('labels.startChat'))}
														</p>
													</div>
												</div>
											)}
										</div>

										<form
											onSubmit={sendMessage}
											className="absolute p-6 w-full flex justify-end items-center w- bottom-0">
											<input
												required
												className="w-full focus:ring-0 focus:outline-none focus:border-primary-light py-4 pl-6 pr-12 border border-[#CFD8DC] rounded-xl bg-[#F5F7FA] text-sm font-semibold text-[#323232]"
												placeholder="Type a message"
												value={newMessage || ''}
												onChange={e => setNewMessage(e.target.value)}
											/>
											<button
												type="submit"
												className="bg-white p-1.5 -translate-x-4 absolute h-[30px] w-[30px] rounded-full flex items-center justify-center">
												<PaperAirplaneIcon className="text-primary-light" />
											</button>
										</form>
									</div>
								)}

								{chats && chats.length === 0 && (
									<div className="flex flex-col h-full w-full justify-center items-center gap-y-6">
										<img src={NoInboxIcon} />
										<h2
											style={{
												color:
													'radial-gradient(235.66% 76.09% at 3.32% 56.06%, #070027 54.79%, #022161 100%)'
											}}
											className="font-domine font-bold">
											{t(tKey('titles.noMessagesYet'))}
										</h2>
										<p
											dangerouslySetInnerHTML={{ __html: t(tKey('labels.noInboxMessage')) }}
											className="text-[#9E9E9E] text-center text-sm font-semibold"
										/>
									</div>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</AppLayout>
	)
}
